TalentInfo.Teaser = {
  rightSidebar: function() {
    $('#brief-tab a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      $('.teaser-right-sidebar .sidebar-element').addClass('d-none');
    });
    $('#summary-tab, #work-history-tab').on('shown.bs.tab', function(e) {
      $('.teaser-right-sidebar .about-interviewer').removeClass('d-none');
    });
    $('#competencies-tab').on('shown.bs.tab', function(e) {
      $('.teaser-right-sidebar .rating-competencies').removeClass('d-none');
    });
  },

  agreeTerms: function() {
    $('#terms_conditions_modal .next-step').click(function(e) {
      if ($('#agree_terms').is(':checked')){
        $('#terms_conditions_modal .first-step').addClass('d-none');
        $('#terms_conditions_modal .second-step').removeClass('d-none');
      } else {
        alert('You need to accept terms');
      }
    });

    $('#terms_conditions_modal .previous-step').click(function(e) {
      $('#terms_conditions_modal .second-step').addClass('d-none');
      $('#terms_conditions_modal .first-step').removeClass('d-none');
    });

    $('#agree-terms-button').click(function(e) {
      if (!$('#agree_fee').is(':checked')){
        e.preventDefault();
        alert('You need to accept terms');
      }
    });
  },

  introVideo: function() {
    if ($('video#intro-video-player').length) {
      var player = $('#intro-video-player').get(0)

      player.onpause = function() {
        $('#intro-video-control').find('span').removeClass('talent-pause');
        $('#intro-video-control').find('span').addClass('talent-play');
      };

      player.onplay = function() {
        $('#intro-video-control').find('span').removeClass('talent-play');
        $('#intro-video-control').find('span').addClass('talent-pause');
      };

      $('video#intro-video-player').bind('loadeddata', function(e) {
        var minutes = parseInt(e.target.duration / 60, 10);
        var seconds = parseInt(e.target.duration % 60);
        $('.intro-video-duration').html(`Duration: ${minutes}min ${seconds}s`);
      });

      $('#intro-video-control').click(function() {
        if($('#intro-video-control').find('span').hasClass('talent-pause')) {
          $('#intro-video-player').get(0).pause();
        } else {
          $('#intro-video-player').get(0).play();
        }
      });
    }
  },

  openRequestBrief: function() {
    $('.open_request_brief').click(function() {
      $('#request_brief_modal').modal('show');
    });
  }
}
