TalentInfo = TalentInfo || {};
TalentInfo.Candidates = {
  showCompetencyDetail: function() {
    $('.competency-list-item').click(function() {
      competencyId = $(this).data('competency-id');

      if($(this).parents('#summary').length) {
        $(`#competency-${competencyId} .hide-competency-detail`).attr('data-return-to', '#summary')
      }

      $('.competency-detail, #competencies-list').addClass('d-none');
      $(`#competency-${competencyId}`).removeClass('d-none');
        switch_to_tab('#competencies');
    });

    $('.hide-competency-detail').click(function(){
      $('#competencies-list').removeClass('d-none');
      $('.competency-detail').addClass('d-none');
    });
  },

  showWorkHistoryDetail: function() {
    $('.history-item, .highlight-item').click(function(e) {
      if ($(e.target).is('a')) { return; }

      organizationId = $(this).data('organization-id');
      if (!organizationId) { return; }

      if($(this).parents('#summary').length) {
        $(`.history-detail#organization-${organizationId} .hide-history-detail`).attr('data-return-to', '#summary')
      }

      $('.history-detail, #history-list').addClass('d-none');
      var orgDetail = $(`#organization-${organizationId}`);
      var rightOrgDetail = $('#org_detail_right');

      if (rightOrgDetail.length) {
        var orgName = orgDetail.data('organizationName');
        var orgWebsite = orgDetail.data('organizationUrl');
        var orgSummary = '';
        orgDetail.data('organizationSummary').split('*').forEach(function(el) { if(!!el) { orgSummary += ("<p>" + el + "</p>") } });
        rightOrgDetail.find('.box-top-header span').html(orgName);
        rightOrgDetail.find('.box-top-header a').attr('href', orgWebsite);
        rightOrgDetail.find('.box-bottom-header .description').html(orgSummary);
        rightOrgDetail.removeClass('d-none');
      }

      orgDetail.removeClass('d-none');

      switch_to_tab('#work-history');
    });

    $('.hide-history-detail').click(function(){
      $('#org_detail_right').addClass('d-none');
      $('#history-list').removeClass('d-none');
      $('.history-detail').addClass('d-none');
    });
  },

  historyDetail: {
    initScrolling: function(e){
      $('.history-nav li a').click(function(event){
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 500);
      })

      $('.references-nav li a').click(function(event){
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 500);
      })

      $('.comments-nav li a').click(function(event){
        event.preventDefault();
        $('.comments-div').addClass('d-none');
        displayDiv = $(this).attr('href');
        $(displayDiv).removeClass('d-none');
        $('.comments-nav li a').removeClass('active');
        $(this).addClass('active');
      })
    }
  },

  rightRail: {
    handleRightRail: function(e){
      $('#brief-tab a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        $('.side-widget.video-player').removeClass('d-none');
        $('.side-widget.conditional').addClass('d-none');
      });
      $('#interview-tab').on('shown.bs.tab', function(e) {
        $('.side-widget').addClass('d-none');
        $('.side-widget.conditional.clips').removeClass('d-none');
        $('html, body').animate({ scrollTop: $('#brief-tab').offset().top - 100 }, 500);
      });
      $('#summary-tab, #work-history-tab').on('shown.bs.tab', function(e) {
        $('.side-widget.conditional.interviewer').removeClass('d-none');
      });
      $('#competencies-tab').on('shown.bs.tab', function(e) {
        $('.side-widget.conditional.competencies').removeClass('d-none');
      });

      $('.nav-link').click(function(){
        nextTab = $(this).attr('id');
        currentTab = $('.nav-link.active').attr('id');
        currentPlayBackTime = 0;
        interviewTabPlayer = $('#interview-player').get(0);
        generalPlayer = $('#video-player').get(0)

        if(currentTab == 'interview-tab') {
          currentPlayBackTime = interviewTabPlayer.currentTime;
        } else {
          currentPlayBackTime = generalPlayer.currentTime
        }

        if(nextTab != 'interview-tab' && currentTab == 'interview-tab') {
          enableGeneralPlayer(currentPlayBackTime, generalPlayer, interviewTabPlayer)
        } else {
          if (nextTab == 'interview-tab') {
            if (interviewTabPlayer.src != generalPlayer.src) { interviewTabPlayer.src = generalPlayer.src; }
            interviewTabPlayer.currentTime = currentPlayBackTime;

            if(TalentInfo.video_playing) {
              interviewTabPlayer.play();
              generalPlayer.pause();
            }
          }
        }
      });

      function enableGeneralPlayer(currentTime, generalPlayer, interviewTabPlayer){
        if (generalPlayer.src != interviewTabPlayer.src) { generalPlayer.src = interviewTabPlayer.src; }
        generalPlayer.currentTime = currentPlayBackTime;

        if(TalentInfo.video_playing) {
          generalPlayer.play();
          interviewTabPlayer.pause();
        }
      }
    }
  }
}

$(window).scroll(function(){
  if ($(this).scrollTop() > 30) {
     $('.profile-header-container.profile-header-cust').addClass('onScroll');
  } else {
     $('.profile-header-container.profile-header-cust').removeClass('onScroll');
  }
});
