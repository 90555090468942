TalentInfo = { video_playing: false };
TalentInfo.Clips = {
  initVideoControls: function() {
    $('.play-stop-button').click(function() {
      player = document.getElementById('video-player');
      iconElement = $(this).find('span.control');
      videoSrc = $(this).data('clip-url');
      playIconClass = 'talent-play';
      pauseIconClass = 'talent-pause';
      playing = iconElement.hasClass('talent-pause');
      stopped = iconElement.hasClass('talent-play');
      clipName = $(this).data('clip-name');
      duration = $(this).data('duration');
      clipId = $(this).data('id');
      activeTab = $('.nav-link.active').attr('id');
      if (activeTab == 'interview-tab') {
        player = $('#interview-player').get(0)
      }

      if(playing) {
        if(player.src == videoSrc) {
          player.pause();
          TalentInfo.video_playing = false;
          $('.play-stop-button span.control').removeClass(pauseIconClass);
          $('.play-stop-button span.control').addClass(playIconClass);
          $('.highlight-item .play-pause-text').html('Play video');
        }
      } else {
        if(player.src != videoSrc) {
          document.getElementById('video-player').src = videoSrc;
          document.getElementById('interview-player').src = videoSrc;
        }
        player.play();
        TalentInfo.video_playing = true;
        $('.play-stop-button span.control').removeClass(pauseIconClass);
        $('.play-stop-button span.control').addClass(playIconClass);
        TalentInfo.Clips.setVideoDetail(clipName, duration, pauseIconClass, videoSrc, clipId);
        $(`.clip-${clipId}`).find('span.control').addClass(pauseIconClass);
        $(`.clip-${clipId}`).find('span.control').removeClass(playIconClass);
        $(`.highlight-item .clip-${clipId} .play-pause-text`).html('Pause video');

        $(`.interview-clips .interview-clip.clip-${clipId}`).addClass('played');
        localStorage.playedClipIds += `${clipId},`

        iconElement.addClass(pauseIconClass);
        iconElement.removeClass(playIconClass);
      }
      $('.active-clip').removeClass('active-clip');
      $(`.sequential-interview-clip.clip-${clipId}`).addClass('active-clip');
    })
  },
  setVideoDetail: function(clipName, duration, icon_class, source, clipId) {
    videoContainer = $('#video-container');
    videoContainer.find('.clip-name').text(clipName);
    videoContainer.find('.clip-duration').text(`Duration: ${duration}`);
    playButton = videoContainer.find('.play-stop-button');
    playButton.data('clip-url', videoSrc);
    playButton.data('id', clipId);
    playButton.data('clip-name', clipName);
    playButton.data('duration', duration);
    videoContainer.find('span.control').addClass(icon_class);
  },
  setPlayedClips: function() {
    if (!localStorage.playedClipIds) { return; }

    clipIds = localStorage.playedClipIds.split(',')
    clipIds.forEach(function(clipId) {
      $(`.interview-clips .interview-clip.clip-${clipId}`).addClass('played');
    });
  },
  initSequentialPlay: function() {
    player = document.getElementById('video-player');
    if (!player) {
      return
    }
    player.addEventListener('ended', function(e) {
      playNext();
    });
    player = $('#interview-player').get(0);
    player.addEventListener('ended', function(e) {
      playNext();
    });
    function playNext() {
      activeClip = $('.sequential-interview-clip.active-clip');
      nextClip = activeClip.next('.sequential-interview-clip');

      if(nextClip.length && $('#interview-tab').hasClass('active')) {
        nextClip.find('.play-stop-button')[0].click();
      } else {
        $('.play-stop-button span.control').removeClass(pauseIconClass);
        $('.play-stop-button span.control').addClass(playIconClass);
        $('.highlight-item .play-pause-text').html('Play video');
        $('.active-clip').removeClass('active-clip');
      }
    }
  }
}
