$(document).on('turbolinks:load', function() {
  switch_reference_comments_tabs();
  bind_reference_modal();
  set_reference_video_durations();
  bind_reference_video_modal();

  $('select#sort_references_by.sort_references').on('change', function(e) {
    var sort_value = $(this).val();
    var candidate_id = $(this).data('candidate');
    $.get(candidate_id + '/references?sort_value=' + sort_value);
  });
});

window.bind_reference_modal = function() {
  $('.reference_block').on('click', function() {
    var reference_id =  $(this).data('reference')
    $.get('/referrers/' + reference_id);
  });
}

window.switch_reference_comments_tabs = function() {
  $('#comments-div .comments_count').html('(' + $('#all-comments .reference_block').length + ')');

  $('.comments-nav a.nav-link').click(function(e) {
    e.preventDefault();
    $('.comments-nav a.nav-link').removeClass('active');
    $(this).addClass('active');
    sectionId = $(this).attr('href');
    $('#comments-div .comments_count').html('(' + $(sectionId + ' .reference_block').length + ')');
    $('.comments-div').addClass('d-none');
    $(sectionId).removeClass('d-none');
  });
}

function set_reference_video_durations() {
  $('.reference_video, .video_thumbnail').bind('loadeddata', function(e) {
    var minutes = parseInt(e.target.duration / 60, 10);
    var seconds = parseInt(e.target.duration % 60);
    $(this).siblings('.clip-duration').html(`Duration: ${minutes}min ${seconds}s`);
  });
}

function bind_reference_video_modal() {
  $('#references-videos .reference-video').on('click', function(e) {
    var avatar = $(this).data('avatar');
    var name = $(this).data('name');
    var role = $(this).data('role');
    var relation = $(this).data('relation');
    var organization = $(this).data('organization');
    var linkedin = $(this).data('linkedin');
    var clipUrl = $(this).data('clipUrl');

    var user_info_block = $('#reference_video_modal .user-info');
    var modal_body = $('#reference_video_modal .modal-body');
    user_info_block.find('.user-img img').attr('src', (avatar || ''));
    user_info_block.find('.name').html(name);
    user_info_block.find('.role').html(`${role} at ${organization}`);
    if (relation) { user_info_block.find('.role').append(` (${relation})`); }
    modal_body.find('a').attr('href', linkedin);
    modal_body.find('.reference_video').attr('src', clipUrl);
    modal_body.find('.play-btn').removeClass('d-none');
    modal_body.find('.caption').html(`Reference from ${name} for ${organization}`);

    $('#reference_video_modal').modal('show');
    if ($('#video-player').length) { $('#video-player').get(0).pause(); }
  });

  $('#reference_video_modal').on('hide.bs.modal', function() {
    $('#reference_video_modal .reference_video').attr('src', '');
  });

  if ($('#reference_video_modal .reference_video').length) {
    $('#reference_video_modal .reference_video').get(0).onplay = function() {
      $('#reference_video_modal .play-btn').addClass('d-none');
    }
  }
}
