function sync_tabs() {
  $('#brief-tab a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    window.history.pushState('', '', $(e.target).attr('href'));
  });

  $('#brief-tab a[data-toggle="tab"]').on('hide.bs.tab', function(e) {
    var tabId = $(e.target).attr('href');
    var visible_detail = $(`${tabId} .hide-detail:visible`);

    visible_detail.removeAttr('data-return-to');
    visible_detail.trigger('click');
  });
}

function open_synced_tab() {
  var activeTab = window.location.href.split('#')[1]
  if($('#' + activeTab).length){
    $(`#brief-tab a[href="#${activeTab}"]`).tab('show');
  } else {
    switch_to_tab($('#brief-tab .nav-link').first().attr('href'));
  }
}

window.switch_to_tab = function show_tab(selector) {
  if($('.modal').length) {
    $('.modal').first().modal('hide');
  }
  $(`#brief-tab a[href="${selector}"]`).tab('show');
  $('html, body').animate({ scrollTop: $('#brief-tab').offset().top - 100 }, 500);
}

function return_to_summary() {
  $('.hide-detail').click(function() {
    var returnTo = $(this).attr('data-return-to');
    if(returnTo) {
      $(this).removeAttr('data-return-to');
      switch_to_tab('#summary');
    }
  });
}

$(document).on('turbolinks:load', function() {
  sync_tabs();
  TalentInfo.Candidates.showWorkHistoryDetail();
  TalentInfo.Candidates.historyDetail.initScrolling();
  TalentInfo.Candidates.showCompetencyDetail();
  TalentInfo.Candidates.rightRail.handleRightRail();
  TalentInfo.Teaser.rightSidebar();
  open_synced_tab();
  return_to_summary();
});
